import i18n from "./locale";

const parseAddress = (addressString) => {
  const notFound = i18n.global.t("id_check.not_found");
  if (!addressString)
    return {
      zip_code: notFound,
      city: notFound,
      street: notFound,
      house_number: notFound,
    };

  const address = addressString.split(",") || [notFound];
  const zipCode = address[0]?.match(/\d+/)?.[0] || notFound;
  const city = address[0]?.replace(/\d+/g, "").trim() || notFound;

  const streetAndNumber = address[1]?.split(" ") || [];
  const street = streetAndNumber.slice(0, -1).join(" ").trim() || notFound;
  const houseNumber = streetAndNumber.slice(-1)[0] || notFound;

  return { zip_code: zipCode, city, street, house_number: houseNumber };
};

const formatDate = (date) => {
  if (!date) return i18n.global.t("id_check.not_found");
  return `${date.day.toString().padStart(2, "0")}.${date.month
    .toString()
    .padStart(2, "0")}.${date.year}`;
};

const formatGender = (gender) => {
  switch (gender) {
    case "f":
      return i18n.global.t("gender.female");

    case "m":
      return i18n.global.t("gender.male");

    case "d":
      return i18n.global.t("gender.diverse");
  }

  return i18n.global.t("id_check.not_found");
};

const formatDocumentType = (type) => {
  switch (type) {
    case "P":
      return i18n.global.t("id_check.codes.P");

    case "ID":
      return i18n.global.t("id_check.codes.ID");

    case "I":
      return i18n.global.t("id_check.codes.ID");

    case "AR":
      return i18n.global.t("id_check.codes.AR");

    case "AF":
      return i18n.global.t("id_check.codes.AR");
  }

  return i18n.global.t("id_check.codes.OTHER");
};

// Utility function to calculate total days from date components
const calculateDays = (day, month, year) => day + month * 30 + year * 365;

// Function to validate birthdate
export const validateBirthdate = (birthdate) => {
  if (!birthdate) {
    return { error: true, message: i18n.global.t("id_check.invalid_document") };
  }

  const days = calculateDays(birthdate.day, birthdate.month, birthdate.year);
  if (!days) {
    return { error: true, message: i18n.global.t("id_check.invalid_document") };
  }

  const today = new Date();
  const todayDays =
    today.getDate() + today.getMonth() * 30 + today.getFullYear() * 365;

  if (todayDays - days < 18 * 365) {
    return { error: true, message: i18n.global.t("id_check.too_young") };
  }

  return { error: false, message: null };
};

// Function to validate expiry date
export const validateExpiryDate = (expiryDate) => {
  if (!expiryDate) {
    return { error: true, message: i18n.global.t("id_check.invalid_document") };
  }

  const days = calculateDays(expiryDate.day, expiryDate.month, expiryDate.year);
  if (!days) {
    return { error: true, message: i18n.global.t("id_check.invalid_document") };
  }

  const today = new Date();
  const todayDays =
    today.getDate() + today.getMonth() * 30 + today.getFullYear() * 365;

  if (days - todayDays < 0) {
    return { error: true, message: i18n.global.t("id_check.document_expired") };
  }

  return { error: false, message: null };
};

export const Wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export default {
  parseAddress,
  formatDate,
  formatGender,
  formatDocumentType,
  validateBirthdate,
  validateExpiryDate,
  Wait,
};
