import { createI18n } from "vue-i18n";

const messages = {};
const requireContext = require.context("./data", true, /\.json$/);

requireContext.keys().forEach((fileName) => {
  const locale = fileName.replace("./", "").replace(".json", "").toUpperCase();
  messages[locale] = requireContext(fileName);
});

const i18n = createI18n({
  locale:
    (typeof localStorage !== "undefined" && localStorage.getItem("locale")) ||
    "DE",
  messages,
});

export default i18n;
