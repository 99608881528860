<template>
  <div>
    <header-component :msg="msg"></header-component>
    <div class="content">
      <div class="front">
        <div class="area">
          <div
            v-if="
              scan_images.frontImage &&
              !idCheckStatus.frontImage.error &&
              !idCheckStatus.frontImage.warning
            "
            class="overlay-green"
          ></div>
          <div
            v-if="idCheckStatus.frontImage.error && !idCheckStatus.frontImage.warning"
            class="overlay-red"
          >
            {{ idCheckStatus.frontImage.message }}
          </div>
          <div
            v-if="!idCheckStatus.frontImage.error && idCheckStatus.frontImage.warning"
            class="overlay-yellow"
          >
            {{ idCheckStatus.frontImage.message }}
          </div>
          <img
            v-if="scan_images.frontImage"
            :src="scan_images.frontImage"
            alt="Front ID Image"
            class="uploaded-image"
          />

          <svg
            v-if="!scan_images.frontImage"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.5 9.25H2C1.59 9.25 1.25 8.91 1.25 8.5C1.25 8.09 1.59 7.75 2 7.75H14.5C14.91 7.75 15.25 8.09 15.25 8.5C15.25 8.91 14.91 9.25 14.5 9.25Z"
              fill="#fff"
            />
            <path
              d="M8 17.25H6C5.59 17.25 5.25 16.91 5.25 16.5C5.25 16.09 5.59 15.75 6 15.75H8C8.41 15.75 8.75 16.09 8.75 16.5C8.75 16.91 8.41 17.25 8 17.25Z"
              fill="#fff"
            />
            <path
              d="M14.5 17.25H10.5C10.09 17.25 9.75 16.91 9.75 16.5C9.75 16.09 10.09 15.75 10.5 15.75H14.5C14.91 15.75 15.25 16.09 15.25 16.5C15.25 16.91 14.91 17.25 14.5 17.25Z"
              fill="#fff"
            />
            <path
              d="M17.56 21.25H6.44C2.46 21.25 1.25 20.05 1.25 16.11V7.89C1.25 3.95 2.46 2.75 6.44 2.75H14.5C14.91 2.75 15.25 3.09 15.25 3.5C15.25 3.91 14.91 4.25 14.5 4.25H6.44C3.3 4.25 2.75 4.79 2.75 7.89V16.1C2.75 19.2 3.3 19.74 6.44 19.74H17.55C20.69 19.74 21.24 19.2 21.24 16.1V14.02C21.24 13.61 21.58 13.27 21.99 13.27C22.4 13.27 22.74 13.61 22.74 14.02V16.1C22.75 20.05 21.54 21.25 17.56 21.25Z"
              fill="#fff"
            />
            <path
              d="M20 10.2499C19.59 10.2499 19.25 9.90994 19.25 9.49994V3.49994C19.25 3.19994 19.43 2.91994 19.71 2.80994C19.99 2.69994 20.31 2.75994 20.53 2.96994L22.53 4.96994C22.82 5.25994 22.82 5.73994 22.53 6.02994C22.24 6.31994 21.76 6.31994 21.47 6.02994L20.75 5.30994V9.49994C20.75 9.90994 20.41 10.2499 20 10.2499Z"
              fill="#fff"
            />
            <path
              d="M17.9995 6.24994C17.8095 6.24994 17.6195 6.17994 17.4695 6.02994C17.1795 5.73994 17.1795 5.25994 17.4695 4.96994L19.4695 2.96994C19.7595 2.67994 20.2395 2.67994 20.5295 2.96994C20.8195 3.25994 20.8195 3.73994 20.5295 4.02994L18.5295 6.02994C18.3795 6.17994 18.1895 6.24994 17.9995 6.24994Z"
              fill="#fff"
            />
          </svg>

          <input type="file" accept="image/*" />
        </div>
        <div class="area-name">{{ $t("id_check.front") }}</div>
      </div>

      <div class="back">
        <div class="area">
          <div
            v-if="
              scan_images.backImage &&
              !idCheckStatus.backImage.error &&
              !idCheckStatus.backImage.warning
            "
            class="overlay-green"
          ></div>
          <div
            v-if="idCheckStatus.backImage.error && !idCheckStatus.backImage.warning"
            class="overlay-red"
          >
            {{ idCheckStatus.backImage.message }}
          </div>
          <div
            v-if="!idCheckStatus.backImage.error && idCheckStatus.backImage.warning"
            class="overlay-yellow"
          >
            {{ idCheckStatus.backImage.message }}
          </div>
          <img
            v-if="scan_images.backImage"
            :src="scan_images.backImage"
            alt="Back ID Image"
            class="uploaded-image"
          />

          <svg
            v-if="!scan_images.backImage"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.5 9.25H2C1.59 9.25 1.25 8.91 1.25 8.5C1.25 8.09 1.59 7.75 2 7.75H14.5C14.91 7.75 15.25 8.09 15.25 8.5C15.25 8.91 14.91 9.25 14.5 9.25Z"
              fill="#fff"
            />
            <path
              d="M8 17.25H6C5.59 17.25 5.25 16.91 5.25 16.5C5.25 16.09 5.59 15.75 6 15.75H8C8.41 15.75 8.75 16.09 8.75 16.5C8.75 16.91 8.41 17.25 8 17.25Z"
              fill="#fff"
            />
            <path
              d="M14.5 17.25H10.5C10.09 17.25 9.75 16.91 9.75 16.5C9.75 16.09 10.09 15.75 10.5 15.75H14.5C14.91 15.75 15.25 16.09 15.25 16.5C15.25 16.91 14.91 17.25 14.5 17.25Z"
              fill="#fff"
            />
            <path
              d="M17.56 21.25H6.44C2.46 21.25 1.25 20.05 1.25 16.11V7.89C1.25 3.95 2.46 2.75 6.44 2.75H14.5C14.91 2.75 15.25 3.09 15.25 3.5C15.25 3.91 14.91 4.25 14.5 4.25H6.44C3.3 4.25 2.75 4.79 2.75 7.89V16.1C2.75 19.2 3.3 19.74 6.44 19.74H17.55C20.69 19.74 21.24 19.2 21.24 16.1V14.02C21.24 13.61 21.58 13.27 21.99 13.27C22.4 13.27 22.74 13.61 22.74 14.02V16.1C22.75 20.05 21.54 21.25 17.56 21.25Z"
              fill="#fff"
            />
            <path
              d="M20 10.2499C19.59 10.2499 19.25 9.90994 19.25 9.49994V3.49994C19.25 3.19994 19.43 2.91994 19.71 2.80994C19.99 2.69994 20.31 2.75994 20.53 2.96994L22.53 4.96994C22.82 5.25994 22.82 5.73994 22.53 6.02994C22.24 6.31994 21.76 6.31994 21.47 6.02994L20.75 5.30994V9.49994C20.75 9.90994 20.41 10.2499 20 10.2499Z"
              fill="#fff"
            />
            <path
              d="M17.9995 6.24994C17.8095 6.24994 17.6195 6.17994 17.4695 6.02994C17.1795 5.73994 17.1795 5.25994 17.4695 4.96994L19.4695 2.96994C19.7595 2.67994 20.2395 2.67994 20.5295 2.96994C20.8195 3.25994 20.8195 3.73994 20.5295 4.02994L18.5295 6.02994C18.3795 6.17994 18.1895 6.24994 17.9995 6.24994Z"
              fill="#fff"
            />
          </svg>

          <input type="file" accept="image/*" />
        </div>
        <div class="area-name">{{ $t("id_check.back") }}</div>
      </div>
    </div>
    <progress-component level="2"></progress-component>
    <language-component back="/email/verify"></language-component>
  </div>
</template>

<script>
import ProgressComponent from "../../components/ProgressComponent.vue";
import LanguageComponent from "../../components/LanguageComponent.vue";
import HeaderComponent from "../../components/HeaderComponent.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: { ProgressComponent, LanguageComponent, HeaderComponent },
  name: "IdCheckPage",
  data() {
    return {
      connection: null,
    };
  },
  computed: {
    ...mapGetters(["scan_images", "idCheckStatus"]),

    msg() {
      if (!this.scan_images.frontImage) {
        return this.$t("id_check.scan_front");
      } else if (this.scan_images.frontImage) {
        return this.$t("id_check.scan_back");
      }

      return "";
    },
  },
  watch: {
    "scan_images.frontImage": function () {
      this.checkImages();
    },
    "scan_images.backImage": function () {
      this.checkImages();
    },
  },
  mounted() {
    this.updateScanFrontImage(null);
    this.updateScanBackImage(null);

    this.websocket();

    this.handler = window.addEventListener("beforeunload", () => {
      this.disableScanner();
    });
  },
  methods: {
    ...mapActions(["updateScanFrontImage", "updateScanBackImage"]),
    enableScanner() {
      const byte = new Uint8Array([0x01]);
      this.connection.send(byte);
    },
    disableScanner() {
      const byte = new Uint8Array([0x02]);
      this.connection.send(byte);
    },
    websocket() {
      this.connection = new WebSocket("ws://localhost:9907");
      this.connection.binaryType = "arraybuffer";

      this.connection.onopen = () => {
        console.log("WebSocket connected");

        this.enableScanner();
      };

      this.connection.onerror = (error) => {
        console.error("WebSocket error:", error);
      };

      this.connection.onclose = () => {
        console.log("connection closed");
      };

      this.connection.onmessage = (e) => {
        const data = JSON.parse(e.data);

        // kein bild
        if (!data.images.document.infrared) {
          return console.log("missing image");
        }

        if (data.document.singleSided) {
          // Reisepass
          this.$store.dispatch("checkPassport", data);
        } else {
          // Auweis
          this.$store.dispatch("checkIdCard", data);
        }
      };
    },

    checkImages() {
      if (
        this.scan_images.frontImage &&
        this.scan_images.backImage &&
        !this.idCheckStatus.backImage.error &&
        !this.idCheckStatus.backImage.warning &&
        !this.idCheckStatus.frontImage.error &&
        !this.idCheckStatus.frontImage.warning
      ) {
        // shutdown scanner
        this.disableScanner();
        this.$store.dispatch("updateIdCheckStatus", {
          frontImage: { error: false, warning: false, message: "" },
          backImage: { error: false, warning: false, message: "" },
        });

        this.$store.dispatch("idcheckDone");

        setTimeout(() => {
          this.$router.push("/id-check/face");
        }, 1000);
      }
    },
  },
  unmounted() {
    this.disableScanner();

    if (this.connection && this.connection.readyState != WebSocket.CONNECTING) {
      console.log("Closing WebSocket connection");
      this.connection.close();
    }

    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    window.removeEventListener("beforeunload", this.handler);
  },
};
</script>
<style scoped>
input[type="file"] {
  pointer-events: none;
}
.content {
  display: flex;
  justify-content: center;
  gap: 3vw;
  margin-top: 7.5vw;
}
.area {
  width: 20vw;
  height: 13vw;
  background-color: rgba(217, 217, 217, 0.5);
  border-radius: 1.5vw;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  box-shadow: 0 10px 20px 0 rgba(83, 83, 83, 0.25);
  transition: background-color 0.3s ease;
}

.uploaded-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.overlay-green {
  position: absolute;
  width: 20vw;
  height: 13vw;
  background-color: rgba(41, 227, 106, 0.5);
  background-image: url("@/assets/img/verified.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 4vw;
  z-index: 99;
}

.overlay-red {
  position: absolute;
  width: 20vw;
  height: 13vw;
  background-color: rgba(255, 0, 0, 0.5);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 4vw;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Roboto Bold";
  font-size: 1.5vw;
  color: #fff;
  text-align: center;
  text-shadow: 0 0 0.5vw rgba(0, 0, 0, 0.5);
}

.overlay-yellow {
  position: absolute;
  width: 20vw;
  height: 13vw;
  background-color: rgba(255, 255, 0, 0.5);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 4vw;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Roboto Bold";
  font-size: 1vw;
  color: #fff;
  text-align: center;
  text-shadow: 0 0 0.5vw rgba(0, 0, 0, 0.5);
}

.area svg {
  height: 5vw;
  width: 5vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.area-name {
  font-family: "Roboto Bold";
  font-size: 1.5vw;
  color: #fff;
  display: block;
  letter-spacing: -0.075vw;
  margin-top: 1vw;
}

.front {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
}
.back {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
}

input[type="file"] {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}
</style>
