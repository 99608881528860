<template>
  <base-keyboard-component
    @addToInput="addToInputHandler"
    @removeLastChar="removeLastCharHander"
    :keyLayout="keyboardLayout"
  ></base-keyboard-component>
</template>

<script>
import BaseKeyboardComponent from "./BaseKeyboardComponent.vue";
import { mapGetters } from "vuex";

export default {
  name: "KeyboardComponent",
  components: { BaseKeyboardComponent },
  data() {
    return {
      layout: "letters",
      currentInput: "",
    };
  },
  mounted() {
    if (this.keyboard?.value === this.$i18n.t("id_check.not_found")) {
      this.$store.commit("setKeyboardValue", "");
    }
  },
  computed: {
    ...mapGetters(["email"]),
    keyboardLayout() {
      return {
        letters: [
          [
            { label: "Q" },
            { label: "W" },
            { label: "E" },
            { label: "R" },
            { label: "T" },
            { label: "Z" },
            { label: "U" },
            { label: "I" },
            { label: "O" },
            { label: "<", action: "remove", classes: "double-col" },
          ],
          [
            { label: "A" },
            { label: "S" },
            { label: "D" },
            { label: "F" },
            { label: "G" },
            { label: "H" },
            { label: "J" },
            { label: "K" },
            { label: "L" },
            { label: "_", action: "remove", classes: "double-col" },
          ],
          [
            { label: "Y" },
            { label: "X" },
            { label: "C" },
            { label: "V" },
            { label: "B" },
            { label: "N" },
            { label: "M" },
            { label: "P" },
            { label: "Ü" },
            { label: "Shift", action: "shift", classes: "double-col" },
          ],
          [
            { label: "123", action: "toggle" },
            { label: "0" },
            { label: "1" },
            { label: "2" },
            { label: "3" },
            { label: "." },
            { label: "@" },
            { label: "Ö" },
            { label: "Ä" },
          ],
        ],
        symbols: [
          [
            { label: "1" },
            { label: "2" },
            { label: "3" },
            { label: "4" },
            { label: "5" },
            { label: "6" },
            { label: "7" },
            { label: "8" },
            { label: "9" },
            { label: "<", action: "remove", classes: "double-col" },
          ],
          [
            { label: "0" },
            { label: "@" },
            { label: "_" },
            { label: "-" },
            { label: "." },
            { label: "+" },
            { label: "#" },
            { label: "$" },
            { label: "&" },
          ],
          [
            { label: "%" },
            { label: "/" },
            { label: "!" },
            { label: "?" },
            { label: "=" },
            { label: "*" },
            { label: "(" },
            { label: ")" },
            { label: "&quot" },
          ],
          [
            { label: "ABC", action: "toggle" },
            { label: ":" },
            { label: ";" },
            { label: "," },
            { label: "'" },
          ],
        ],
      };
    },
  },
  methods: {
    toggleLayout() {
      this.layout = this.layout === "letters" ? "symbols" : "letters";
    },
    addToInputHandler(char) {
      let initialValue = this.email || "";

      initialValue += char;

      this.$store.commit("setEmail", initialValue);
    },
    removeLastCharHander() {
      let initialValue = this.email || "";

      initialValue = initialValue.slice(0, -1);

      this.$store.commit("setEmail", initialValue);
    },
  },
};
</script>

<style scoped>
.row {
  display: flex;
  gap: 0.5vw;
  width: 100%;
  margin-bottom: 0.5vw;
}
.item {
  background: rgba(217, 217, 217, 0.5);
  height: 4vw;
  width: 4vw;
  border-radius: 1vw;
  font-family: "Roboto Bold";
  font-size: 2vw;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 10px 20px 0 rgba(83, 83, 83, 0.25);
  transition: transform 0.2s ease;
}
.item:hover,
.item:active {
  background: rgba(217, 217, 217, 0.8);
  cursor: pointer;
  transform: scale(1.1);
  animation: hoverEffect 1.5s ease-out;
}

@keyframes hoverEffect {
  0% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.item-small {
  font-size: 1.5vw;
}
.double-col {
  width: 8.5vw;
}
</style>
