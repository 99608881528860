import { createStore } from "vuex";

import { actions } from "./actions";
import { getters } from "./getters";

const store = createStore({
  state() {
    return {
      data: {
        gender: "",
        phone: "",
        phoneDialCode: "",
        authPage: "register",
        sms_code: ["", "", "", ""],
        sms_code_timeout: false,
        email_code: ["", "", "", ""],
        email_code_timeout: false,
        images: {
          scan: {
            frontImage: null,
            backImage: null,
          },
          face: "",
          photo: "",
        },
        currentJob: {
          name: "",
        },
        email: "",
        selectedPartners: {},
      },
      keyboard: {
        show: false,
        value: "",
        inputKey: "",
      },
      partners: [
        {
          image: require("@/assets/img/partner/interwetten.png"),
          name: "Interwetten",
          type: "Sportwetten",
        },
        {
          image: require("@/assets/img/partner/betano.png"),
          name: "Betano",
          type: "Sportwetten",
        },
        {
          image: require("@/assets/img/partner/wettarena.png"),
          name: "Wettarena",
          type: "Sportwetten",
        },
        {
          image: require("@/assets/img/partner/bet365.png"),
          name: "Bet365",
          type: "Sportwetten",
        },
      ],
      countries: [
        {
          image: require("@/assets/img/lang/de.webp"),
          name: "Deutsch",
          code: "DE",
        },
        {
          image: require("@/assets/img/lang/en.svg"),
          name: "English",
          code: "EN",
        },
        {
          image: require("@/assets/img/lang/es.jpg"),
          name: "Español",
          code: "ES",
        },
        {
          image: require("@/assets/img/lang/fr.jpg"),
          name: "Français",
          code: "FR",
        },
        {
          image: require("@/assets/img/lang/tr.webp"),
          name: "Türkçe",
          code: "TR",
        },
      ],
      idCheckData: {
        firstname: "",
        birthplace: "",
        id_number: "",

        lastname: "",
        gender: "",
        id_expiry: "",

        birth_name: "",
        nationality: "",
        id_issuer: "",

        birthdate: "",
        document_type: "",
      },
      idCheckStatus: {
        frontImage: {
          error: false,
          warning: false,
          message: "",
        },
        backImage: {
          error: false,
          warning: false,
          message: "",
        },
      },
      addressCheckData: {
        zip_code: "",
        city: "",
        street: "",
        house_number: "",
      },
      jobs: [
        {
          name: "employee",
        },
        {
          name: "self_employed",
        },
        {
          name: "student",
        },
        {
          name: "unemployed",
        },
        {
          name: "retired",
        },
        {
          name: "other",
        },
      ],
      templateMails: [
        {
          mail: "@gmail.com",
        },
        {
          mail: "@web.de",
        },
        {
          mail: "@gmx.de",
        },
        {
          mail: "@outlook.com",
        },
        {
          mail: "@hotmail.com",
        },
      ],
    };
  },
  mutations: {
    setSmsCodeTimeout(state, payload) {
      state.data.sms_code_timeout = payload;
    },
    setIdCheckData(state, payload) {
      state.idCheckData = payload;
    },
    setEmailCodeTimeout(state, payload) {
      state.data.email_code_timeout = payload;
    },
    setKeyboardValue(state, payload) {
      state.keyboard.value = payload;
    },
    setKeyboardInputKey(state, payload) {
      state.keyboard.inputKey = payload;
    },
    setKeyboard(state, payload) {
      state.keyboard.show = payload;
    },
    setAdressCheckData(state, payload) {
      state.addressCheckData[payload.key] = payload.value;
    },
    toggleSelectedPartner(state, payload) {
      const partner = state.data.selectedPartners[payload.name];
      if (partner) {
        delete state.data.selectedPartners[payload.name];
      } else {
        state.data.selectedPartners[payload.name] = payload;
      }
    },
    setEmail(state, payload) {
      state.data.email = payload;
    },
    setCurrentJob(state, payload) {
      state.data.currentJob = payload;
    },
    setGender(state, payload) {
      state.data.gender = payload;
    },
    setPhone(state, payload) {
      state.data.phone = payload;
    },
    setPhoneDialCode(state, payload) {
      state.data.phoneDialCode = payload;
    },
    setPhotoImage(state, image) {
      state.data.images.photo = image;
    },
    // from photo scan
    setScanFrontImage(state, image) {
      state.data.images.scan.frontImage = image;
    },
    setScanBackImage(state, image) {
      state.data.images.scan.backImage = image;
    },
    clearData(state) {
      state.data = {
        gender: "",
        phone: "",
        phoneDialCode: "",
        authPage: "register",
        sms_code: ["", "", "", ""],
        sms_code_timeout: false,
        email_code_timeout: false,
        email_code: ["1", "2", "3", "4"],
        images: {
          scan: {
            frontImage: null,
            backImage: null,
          },
          face: "",
          photo: "",
        },
        currentJob: {
          name: "",
        },
        email: "",
        selectedPartners: {},
      };
    },
    setAuthPage(state, payload) {
      state.data.authPage = payload;
    },
    UPDATE_FACE_IMAGE(state, faceImage) {
      state.data.images.face = faceImage;
    },
    SET_ADDRESS_DATA(state, addressData) {
      console.log("SET_ADDRESS_DATA", addressData);
      state.addressCheckData = {
        zip_code: addressData.zip_code || "",
        city: addressData.city || "",
        street: addressData.street || "",
        house_number: addressData.house_number || "",
      };
    },
    UPDATE_ID_CHECK_DATA(state, payload) {
      state.idCheckData = {
        ...state.idCheckData,
        ...payload,
      };
    },
    UPDATE_FRONT_IMAGE_STATUS(state, { warning, error, message }) {
      state.idCheckStatus.frontImage.warning = warning || false;
      state.idCheckStatus.frontImage.error = error || false;
      state.idCheckStatus.frontImage.message = message || "";
    },
    UPDATE_BACK_IMAGE_STATUS(state, { warning, error, message }) {
      state.idCheckStatus.backImage.warning = warning || false;
      state.idCheckStatus.backImage.error = error || false;
      state.idCheckStatus.backImage.message = message || "";
    },
  },
  actions: actions,
  getters: getters,
});

export default store;
