import api from "../../api";
import i18n from "../../locale";
import helpers from "../../helpers";

export const actions = {
  updateIdCheckData({ commit, state }, data) {
    if (data?.images?.face?.visible) {
      commit(
        "UPDATE_FACE_IMAGE",
        "data:image/jpeg;base64," + data.images.face.image
      );
    }

    if (data?.ocr) {
      // Update address details
      if (data.ocr?.address) {
        const addressData = helpers.parseAddress(data.ocr?.address);
        commit("SET_ADDRESS_DATA", addressData);
      }

      if (data.ocr?.birthName) {
        // Commit ID check details
        commit("UPDATE_ID_CHECK_DATA", {
          birth_name: data.ocr?.birthName,
        });
      }

      if (data.ocr?.birthPlace) {
        commit("UPDATE_ID_CHECK_DATA", {
          birthplace: data.ocr?.birthPlace,
        });
      }
    }

    if (data?.mrz) {
      commit("UPDATE_ID_CHECK_DATA", {
        firstname: data.mrz?.firstname || i18n.global.t("id_check.not_found"),
        lastname: data.mrz?.lastname || i18n.global.t("id_check.not_found"),
        birthdate:
          helpers.formatDate(data.mrz?.birthdate) ||
          i18n.global.t("id_check.not_found"),
        gender:
          helpers.formatGender(state.data.gender) ||
          i18n.global.t("id_check.not_found"),
        nationality:
          data.mrz?.nationality || i18n.global.t("id_check.not_found"),
        document_type:
          helpers.formatDocumentType(data.mrz?.document?.code) ||
          i18n.global.t("id_check.not_found"),
        id_number:
          data.mrz?.document?.number || i18n.global.t("id_check.not_found"),
        id_expiry:
          helpers.formatDate(data.mrz?.document?.expiryDate) ||
          i18n.global.t("id_check.not_found"),
        id_issuer:
          data.mrz?.document?.issuer || i18n.global.t("id_check.not_found"),
      });
    }
  },

  checkIdCard({ dispatch, commit }, data) {
    if (data.document.page == 1) {
      // Ausweis Vorderseite
      if (data.document.fuzzy) {
        commit("UPDATE_FRONT_IMAGE_STATUS", {
          warning: true,
          message: i18n.global.t("id_check.fuzzy"),
        });
        dispatch(
          "updateScanFrontImage",
          "data:image/jpeg;base64," + data.images.document.infrared
        );
        return;
      }

      if (!data.document.valid) {
        commit("UPDATE_FRONT_IMAGE_STATUS", {
          error: true,
          message: i18n.global.t("id_check.invalid_document"),
        });
        return;
      }

      commit("UPDATE_FRONT_IMAGE_STATUS", {
        warning: false,
        error: false,
        message: "",
      });

      dispatch(
        "updateScanFrontImage",
        "data:image/jpeg;base64," + data.images.document.infrared
      );

      dispatch("updateIdCheckData", data);
    } else if (data.document.page == 2) {
      console.log("test");
      // Ausweis Rückseite
      if (data.mrz && !data.mrz.valid) {
        commit("UPDATE_BACK_IMAGE_STATUS", {
          error: true,
          message: i18n.global.t("id_check.mrz_invalid"),
        });
        return;
      }

      if (data.document.fuzzy) {
        commit("UPDATE_BACK_IMAGE_STATUS", {
          warning: true,
          message: i18n.global.t("id_check.fuzzy"),
        });

        dispatch(
          "updateScanBackImage",
          "data:image/jpeg;base64," + data.images.document.infrared
        );
        return;
      }

      if (!data.document.valid) {
        commit("UPDATE_BACK_IMAGE_STATUS", {
          error: true,
          message: i18n.global.t("id_check.invalid_document"),
        });
        return;
      }

      const birthdateValidator = helpers.validateBirthdate(data.mrz.birthdate);

      if (birthdateValidator.error) {
        commit("UPDATE_BACK_IMAGE_STATUS", {
          error: true,
          message: birthdateValidator.message,
        });
      }

      const expirydateValidator = helpers.validateExpiryDate(
        data.mrz.id_expiry
      );

      if (expirydateValidator.error) {
        commit("UPDATE_BACK_IMAGE_STATUS", {
          error: true,
          message: expirydateValidator.message,
        });
      }

      commit("UPDATE_BACK_IMAGE_STATUS", {
        warning: false,
        error: false,
        message: "",
      });

      dispatch(
        "updateScanBackImage",
        "data:image/jpeg;base64," + data.images.document.infrared
      );

      dispatch("updateIdCheckData", data);
    }
  },
  idcheckDone({ commit, state }) {
    // check for birtName and birthPlace set
    if (!state.idCheckData.birth_name) {
      commit("UPDATE_ID_CHECK_DATA", {
        // last name or not found
        birth_name:
          state.idCheckData.lastname || i18n.global.t("id_check.not_found"),
      });
    }

    if (!state.idCheckData.birthplace) {
      commit("UPDATE_ID_CHECK_DATA", {
        birthplace: i18n.global.t("id_check.not_found"),
      });
    }
  },
  checkPassport({ dispatch, commit }, data) {
    if (data.document.fuzzy) {
      commit("UPDATE_FRONT_IMAGE_STATUS", {
        warning: true,
        message: i18n.global.t("id_check.fuzzy"),
      });
      return;
    }

    if (!data.document.valid) {
      commit("UPDATE_FRONT_IMAGE_STATUS", {
        error: true,
        message: i18n.global.t("id_check.invalid_document"),
      });
      return;
    }

    const birthdateValidator = helpers.validateBirthdate(data.mrz.birthdate);

    if (birthdateValidator.error) {
      commit("UPDATE_FRONT_IMAGE_STATUS", {
        error: true,
        message: birthdateValidator.message,
      });
    }

    const expirydateValidator = helpers.validateExpiryDate(data.mrz.id_expiry);

    if (expirydateValidator.error) {
      commit("UPDATE_FRONT_IMAGE_STATUS", {
        error: true,
        message: expirydateValidator.message,
      });
    }

    commit("UPDATE_FRONT_IMAGE_STATUS", {
      warning: false,
      error: false,
      message: "",
    });

    dispatch(
      "updateScanFrontImage",
      "data:image/jpeg;base64," + data.images.document.infrared
    );

    dispatch(
      "updateScanBackImage",
      "data:image/jpeg;base64," + data.images.document.infrared
    );

    dispatch("updateIdCheckData", data);
  },
  startSmsCodeTimeout({ commit }) {
    setTimeout(() => {
      commit("setSmsCodeTimeout", false);
    }, 30000);
  },
  startEmailCodeTimeout({ commit }) {
    setTimeout(() => {
      commit("setEmailCodeTimeout", false);
    }, 30000);
  },

  sendSmsCode({ state }) {
    const phone = state.data.phoneDialCode + state.data.phone;
    console.log("Sending SMS code to", phone);

    if (process.env.VUE_APP_MODE == "development") {
      return true;
    }

    if (!state.data.phone) {
      console.error("Phone number is not set");
      return;
    }
    console.log(process.env.VUE_APP_MODE);
    console.log(process.env.VUE_APP_API_URL + "/sendOTP");
    api
      .post("/sendOTP", {
        sms: state.data.phone,
      })
      .then((response) => {
        if (!response.status === 200) {
          console.log(response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async checkSmsCode({ state }, inputCode) {
    if (process.env.VUE_APP_MODE == "development") {
      return true;
    }

    let code = inputCode.join("");
    code = parseInt(code);

    const response = await api
      .post("/verifyOTP", {
        sms: state.data.phone,
        otp: code,
      })
      .catch((error) => {
        console.log(error);
      });

    if (!response || response.status != 200) {
      return false;
    }
    return response.data.isOTPValid || false;
  },
  sendEmailCode({ state }) {
    if (process.env.VUE_APP_MODE == "development") {
      return true;
    }

    if (!state.data.email) {
      console.error("Email is not set");
      return;
    }

    api
      .post("/sendOTP", {
        email: state.data.email,
      })
      .then((response) => {
        if (!response.status === 200) {
          console.log(response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async checkEmailCode({ state }, inputCode) {
    if (process.env.VUE_APP_MODE == "development") {
      return true;
    }
    let code = inputCode.join("");
    code = parseInt(code);

    const response = await api
      .post("/verifyOTP", {
        email: state.data.email,
        otp: code,
      })
      .catch((error) => {
        console.log(error);
      });

    if (!response || response.status != 200) {
      return false;
    }

    return response.data.isOTPValid || false;
  },
  async compareFaces({ state }) {
    if (process.env.VUE_APP_MODE == "development") {
      return true;
    }
    // remove data:image/jpeg;base64, from the image
    let cameraImage = state.data.images.photo.split(",")[1];
    let documentImage = state.data.images.face.split(",")[1];

    cameraImage = documentImage;

    console.log("cameraImage", cameraImage);
    console.log("documentImage", documentImage);

    const response = await api
      .post("/compareFaces", {
        cameraImage: cameraImage,
        documentImage: documentImage,
      })
      .catch((error) => {
        console.log(error);
      });

    if (!response || response.status != 200) {
      return false;
    }

    return response.data.Similarity >= 80;
  },
  updateScanFrontImage({ commit }, image) {
    commit("setScanFrontImage", image);
  },
  updateScanBackImage({ commit }, image) {
    commit("setScanBackImage", image);
  },
  updatePhotoImage({ commit }, image) {
    commit("setPhotoImage", image);
  },
  updateGender({ commit }, gender) {
    commit("setGender", gender);
  },
};
